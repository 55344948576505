$primary-color: #FD1822;
$primary-background-color: #FD1822;
$primary-background-color-75: rgba(253,24,34, 0.75);
$primary-background-color-50: rgba(253,24,34, 0.5);
$primary-background-color-25: #dddddd;
$primary-background-color-light: #eeeeee;
$primary-background-color-dark: #dddddd;

$secondary-color: #6f706f;
$secondary-background-color: #6f706f;
$secondary-background-color-75: rgba(111, 112, 111,  0.75);
$secondary-background-color-50: rgba(111, 112, 111, 0.5);
$secondary-background-color-25: rgba(111, 112, 111, 0.35);
$secondary-background-color-light: #dfe5df;

$color-white: #ffffff;
$color-black: #000000;

$red-color: #e63744;
$red-background-color: #e63744;

$text-color: #333333;
$title-color: #FD1822;
$h1-color: #FD1822;


$mainCookieBG: $primary-background-color-75;
$cookieLinkColor: #fff;
$cookieSlideActiveColor: darken($mainCookieBG, 30%);
$cookieCloseIconColorOnHover: red;
$cookiePrivacyLinkColorOnHover: darken($mainCookieBG, 30%);

$cookieBtnBG: #fff;
