@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro');
html {
	width: 100%;
}

body {
	width: 100%;
	font-family: 'Source Sans Pro', sans-serif !important;
	font-size: 15px;
	line-height: 1.7;
	color: $text-color;
}

/* Heading */
h1, h2, h3, h4, h5, h6 {
	font-weight: 600;
	color: $title-color;
	line-height: 1.2;
	font-family: 'Source Sans Pro', sans-serif !important;
}

/* header */
header .logo{
    position: relative;
    width: 100%;
    .logo-img{
        margin: 0 auto;
    }
}
header .logo .nvm{
    display: none;
}
.aanbod-header-block header .phonenumber{
    display: inline-block;
    text-align: right;
    left: 0px;
    top: 15px;
    right: auto;
    overflow: hidden;
    background-color: $secondary-color;
    padding: 15px 30px;
    &:hover{
        background-color: $primary-color;
    }
    @media (min-width: 992px) {
        left: auto;
        right: 210px;
        top: 15px;
        overflow: auto;
    }
    a{
        color: white;
        font-size: 0rem;
        width :60px;
        height: 54px;
        @media (min-width: 992px) {
            font-size: 1rem;
            height: auto;
            width: auto;
        }
    }
    .fas.fa-phone{
        color: white;
        fill: white;
        font-size: 1rem;
    }
}
.aanbod-header-block header.narrow .phonenumber{
    right: 149px;
}
.card__count{
    color: $black;
}
/* footer */
.footer .container {
    padding: 60px 0;
    background-color:  $title-color;
    color: $color-white;
    h1, h2, h3, h4, h5, h6{
        color: $color-white;
    }
    a{
        color: $color-white;
    }

}
.footer:before{
    background-color: #c20008;
}
.bg-blue{
    color: $white;
}


/* detail page */
.detail-header-image .top-detail-right{
    background-color: #c20008;
}
.detail-header-image .top-detail-right h1,
.detail-header-image .top-detail-right h2,
.detail-header-image .top-detail-right h3,
.detail-header-image .top-detail-right h4,
.detail-header-image .top-detail-right h5,
.detail-header-image .top-detail-right h6,
.top-detail-left .table-title h6
{
    color: $white;
}
.table-title h6{
    color: $black;
}
.countdown{
    color: $title-color;
}
.bid-history .table-title h6,
.bid-history{
    color: $title-color;
}

@media (min-width: 992px) {
    section.filter-bar.page .content-title .title-line.gold {
        background-color: #dfd6d1;
    }
}
.row.top-detail .top-detail-right .alert.alert-warning{
    color: yellow;
}
/* footer */
.aligned{
    &.left-align{
        .logo-left{
            .footer-nvm svg{
                fill: #fff;
            }
            .footer-funda svg{
                fill: #fff;
            }
        }
        .logo-right{
            .footer-nwwi svg{
                fill: #fff;
            }
            .footer-nrvg svg{
                fill: #fff;
            }
        }
    }
}
